<template>
    <v-card flat class="fill-height box">
        <v-app-bar flat class="row header mx-0">
            <img :src="require('../assets/LeonardoIcon.svg')" width="75" />
            <v-divider class="mx-3" inset vertical></v-divider>
            <span class="headline">Rental Shop</span>

        </v-app-bar>

        <v-card-text class="row content mx-0">
            <v-row align-content="center" justify="center" no-gutters>
                <v-col cols="12" md="10" lg="7" xl="6" :class="$vuetify.breakpoint.smAndDown ? 'mb-10' : ''">
                    <v-card-title class="display-1 pl-0">Thank you!</v-card-title>
                    <!-- <v-card-subtitle class="pl-0">We have received your order. Your order details are below.</v-card-subtitle> -->

                    <p>We have received your order. If you chose to pay at cashier, notify your stylist to complete your transaction.</p>

                    <div v-if="order != null">
                        <v-row class="mt-5" no-gutters>

                            <v-col cols="12" md="6">
                                <div class="title mb-3">Order details</div>
                                <v-row no-gutters>
                                    <v-col cols="6"><b>Order #</b>:</v-col>
                                    <v-col cols="6" class="mb-2">{{ order.foreignId }}</v-col>
                                    <v-col cols="6"><b>Order date</b>:</v-col>
                                    <v-col cols="6" class="mb-2">{{ toDate( order.orderDate, 'YYYY-MM-DD @ hh:mm a' ) }}</v-col>
                                    <v-col cols="6"><b>Pickup at</b>:</v-col>
                                    <v-col cols="6" class="mb-2">{{ order.pickupLocation.name }} <br> Tel. {{ order.pickupLocation.phone }}</v-col>
                                    <v-col cols="6"><b>When can I pickup</b>:</v-col>
                                    <v-col cols="6" class="mb-2">{{ toDate( order.wantDate ) }}</v-col>
                                    <v-col cols="6" v-if="order.payments.length > 0"><b>Payment confirmation</b>:</v-col>
                                    <v-col cols="6" v-if="order.payments.length > 0">{{ order.payments[0].confirmationNumber }} ({{ order.payments[0].paymentMethod }})</v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="12" md="6" :class="$vuetify.breakpoint.smAndDown ? 'mt-5' : ''">
                                <div class="title mb-3">Your outfit</div>
                                <v-list>
                                    <v-list-item v-for="item in order.items" :key="item.itemId" class="px-0">
                                        <v-list-item-avatar size="56">
                                            <v-img :src="item.imgSrc"></v-img>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.productName }}</v-list-item-title>
                                            <v-list-item-subtitle>{{ item.category }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                                <v-divider class="my-5" inset></v-divider>

                                <v-row no-gutters>
                                    <v-col cols="6">Subtotal:</v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( order.subtotal ) }}</v-col>
                                    <v-col cols="6">
                                        Other charges / discounts:
                                        <v-tooltip top>
                                            This includes a summarized amount of the damage waiver, discounts and extra charges.
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon small v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                                            </template>
                                        </v-tooltip>
                                    </v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( order.otherChargesDiscount ) }}</v-col>
                                    <v-col cols="6">Tax (IVU):</v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( order.tax ) }}</v-col>
                                    <v-col cols="6">Total:</v-col>
                                    <v-col cols="6" class="text-right">{{ toMoney( order.total ) }}</v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-btn depressed block color="error" class="mt-8 white--text" @click="restart" style="text-transform:none;">
                            Restart
                        </v-btn>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { API } from '@/inc/api';
import { _st } from '@/softech';

export default {
    props: {
        foreignId: { type: String, default: '' },
    },
    data: () => ({
        order: null,
    }),
    mounted() {
        this.loadOrder();
    },
    methods: {
        async loadOrder() {
            if( _st.isNUE( this.foreignId ) )
                return;

            try {
                let api = new API();

                let res = await api.get(`/order/${this.foreignId}`);

                if( res.data.status !== true ) {
                    this.mxShowMessage(res.data.error === true ? 'Error' : 'Warning', res.data.message, res.data.error === true ? 'error' : 'warning');
                }

                this.order = res.data.data;
            }
            catch(error) {
                this.mxShowMessage('Error', error, 'error');
            }
        },
        toMoney(n) {
            return _st.toMoney(n);
        },
        toDate(d, format = 'YYYY-MM-DD') {
            return _st.formatDate(d, format);
        }
    }
}
</script>

<style lang="scss" scoped>

</style>